var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import Modal from "react-modal";
import { popularCompetition, sportsbookFilter } from "../../../store/sportsbook.store";
import { setFilterCompetitions, setFilterDays, setFilterMBS } from "../../../services/sportsbook.services";
import { longDays } from "../../../definitions/global.vars";
import { ServerDate, useForceUpdate } from "../../../lib/misc.functions";
import { useNavigate } from "react-router-dom";
import iconCustomRatio from "../../../assets/img/fire.gif";
import { CompetitionLabelHelper } from "../../../definitions/constants/competition-maps";
var FilterDaysList = [];
function generateDaysList(shownSportsBook) {
    var label = '';
    var filterDays = [];
    var today = ServerDate().setHours(0, 0, 0, 0);
    var _loop_1 = function (i) {
        var tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + i);
        if (i === 0) {
            label = 'Bugün';
        }
        else if (i === 1) {
            label = 'Yarın';
        }
        else {
            label = longDays[tomorrow.getDay()];
        }
        // @ts-ignore
        var eventCount = shownSportsBook.filter(function (el) {
            var eventDate = new Date(new Date(el.eventDate).setHours(0, 0, 0, 0));
            if (eventDate.toISOString() === tomorrow.toISOString()) {
                return el;
            }
        }).length;
        var offset = tomorrow.getTimezoneOffset() / 60;
        tomorrow.setTime(tomorrow.getTime() - offset * 3600 * 1000);
        if (eventCount > 0) {
            filterDays.push({
                count: eventCount,
                index: tomorrow.toISOString().substring(0, 10),
                label: label,
            });
        }
    };
    for (var i = 0; i < 7; i++) {
        _loop_1(i);
    }
    // console.log(filterDays)
    return filterDays;
}
function SportsBookFilterComponent(props) {
    // console.log("props", props)
    var originSportsbookList = props.originSportsbookList;
    var params = props.params;
    var setKeywordFilter = props.setKeywordFilter;
    var competitionGroups = props.competitionGroups;
    var parentForceUpdate = props.forceUpdate;
    var navigate = useNavigate();
    var _a = useState(false), competitionModalIsOpened = _a[0], setCompetitionModalIsOpened = _a[1];
    var _b = useState(false), daysModalIsOpened = _b[0], setDaysModalIsOpened = _b[1];
    var _c = useState(false), sportList = _c[0], setSportList = _c[1];
    var forceUpdate = useForceUpdate();
    FilterDaysList = useMemo(function () { return generateDaysList(originSportsbookList); }, [params.sportSlug]);
    var goToUrl = function (sportSlug, eventType) {
        if (eventType === '2') {
            navigate("/iddaa/" + sportSlug + '/uzunvadeli');
            // document.body.style.overflow = 'unset';
        }
        else {
            navigate("/iddaa/" + sportSlug + '/tumu');
            // document.body.style.overflow = 'unset';
        }
        setSportList(false);
    };
    var popularCompetitions = popularCompetition.filter(function (el) { return el.sport === params.sportSlug; });
    return (_jsxs(_Fragment, { children: [_jsxs(Modal, __assign({ isOpen: competitionModalIsOpened, onRequestClose: function () {
                    setCompetitionModalIsOpened(false);
                }, ariaHideApp: false, style: { content: { background: 'var(--color-bg-white)', height: "calc(55vh + 125px)", width: "320px", left: "calc( 50% - 160px )", top: "120px" } } }, { children: [_jsx("div", __assign({ className: "modal-header" }, { children: _jsx("h2", { children: "Lig Se\u00E7iniz" }, void 0) }), void 0), _jsxs("ul", __assign({ className: "competitionFilterList" }, { children: [popularCompetitions.length > 0 &&
                                _jsxs("li", { children: [_jsx("a", { children: "Pop\u00FCler Ligler" }, void 0), _jsx("ul", { children: popularCompetitions.map(function (el, subKey) {
                                                return (_jsxs("li", { children: [_jsx("div", __assign({ className: "float-left" }, { children: _jsxs("label", { children: [_jsx("input", { type: "checkbox", checked: el.id.split(',').every(function (e) { return sportsbookFilter.filterCompetition.indexOf(e) > -1; }), onChange: function () {
                                                                            setFilterCompetitions(el.id);
                                                                            forceUpdate();
                                                                        } }, void 0), CompetitionLabelHelper(el.name)] }, void 0) }), void 0), _jsx("div", { className: "float-right" }, void 0), _jsx("div", { className: "clearfix" }, void 0)] }, subKey));
                                            }) }, void 0)] }, void 0), competitionGroups.map(function (compGroup, key) {
                                return (_jsxs("li", { children: [_jsx("a", { children: compGroup.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }, void 0), _jsx("ul", { children: Object.entries(compGroup.competitions).map(function (el, subKey) {
                                                return (_jsxs("li", { children: [_jsx("div", __assign({ className: "float-left" }, { children: _jsxs("label", { children: [_jsx("input", { type: "checkbox", checked: sportsbookFilter.filterCompetition.indexOf(el[1][0].competitionId.toString()) !== -1, onChange: function () {
                                                                            // setFilterCompetitions(el[1][0].competitionId);
                                                                            var competitionIds = el[1].map(function (item) { return item.competitionId; }).filter(function (value, index, current_value) { return current_value.indexOf(value) === index; }).join(',');
                                                                            setFilterCompetitions(competitionIds);
                                                                            forceUpdate();
                                                                        } }, void 0), CompetitionLabelHelper(el[1][0].competitionName)] }, void 0) }), void 0), _jsx("div", __assign({ className: "float-right" }, { children: el[1].length }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }, subKey));
                                            }) }, void 0)] }, key));
                            })] }), void 0), _jsx("div", __assign({ className: "modal-footer" }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("button", __assign({ className: "btn btn-primary", onClick: function () {
                                        setCompetitionModalIsOpened(false);
                                        parentForceUpdate();
                                    } }, { children: "Filtrele" }), void 0), _jsx("button", __assign({ className: "btn btn-warning", onClick: function () {
                                        setFilterCompetitions("clear");
                                        setCompetitionModalIsOpened(false);
                                        parentForceUpdate();
                                    } }, { children: "Temizle" }), void 0), _jsx("button", __assign({ className: "btn btn-default", onClick: function () {
                                        setCompetitionModalIsOpened(false);
                                    } }, { children: "Vazge\u00E7" }), void 0)] }), void 0) }), void 0)] }), void 0), _jsxs(Modal, __assign({ isOpen: daysModalIsOpened, onRequestClose: function () {
                    setDaysModalIsOpened(false);
                }, ariaHideApp: false, style: { content: { background: 'var(--color-bg-white)', height: "295px", width: "320px", left: "calc( 50% - 160px )", top: "120px" } } }, { children: [_jsx("div", __assign({ className: "modal-header" }, { children: _jsx("h2", { children: "Tarih Se\u00E7iniz" }, void 0) }), void 0), _jsx("ul", __assign({ className: "competitionFilterList", style: { height: "170px" } }, { children: FilterDaysList.map(function (compGroup, key) {
                            return (_jsx("li", { children: _jsx("ul", { children: _jsxs("li", { children: [_jsx("div", __assign({ className: "float-left" }, { children: _jsxs("label", { children: [_jsx("input", { type: "checkbox", checked: sportsbookFilter.filterDays.indexOf(compGroup.index) !== -1, onChange: function () {
                                                                setFilterDays(compGroup.index);
                                                                forceUpdate();
                                                            } }, void 0), compGroup.label] }, void 0) }), void 0), _jsx("div", __assign({ className: "float-right" }, { children: compGroup.count }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }, void 0) }, void 0) }, key));
                        }) }), void 0), _jsx("div", __assign({ className: "modal-footer" }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("button", __assign({ className: "btn btn-primary", onClick: function () {
                                        setDaysModalIsOpened(false);
                                        parentForceUpdate();
                                    } }, { children: "Filtrele" }), void 0), _jsx("button", __assign({ className: "btn btn-warning", onClick: function () {
                                        setFilterDays("clear");
                                        setDaysModalIsOpened(false);
                                        parentForceUpdate();
                                    } }, { children: "Temizle" }), void 0), _jsx("button", __assign({ className: "btn btn-default", onClick: function () {
                                        setDaysModalIsOpened(false);
                                    } }, { children: "Vazge\u00E7" }), void 0)] }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ style: { marginTop: (innerWidth < 968 ? '-25px' : 'unset') }, className: "sportsbook-filter" }, { children: _jsx("div", __assign({ className: "selections col-lg-12 col-md-12 p-0 text-right mb-3" }, { children: _jsxs("div", { children: [_jsxs("button", __assign({ className: "btn btn-small " + (sportsbookFilter.filterCompetition.length > 0 ? 'btn-warning' : 'btn-outline-white'), onClick: function () {
                                    setCompetitionModalIsOpened(true);
                                } }, { children: [_jsx("span", __assign({ className: "visible-sm visible-detail" }, { children: "Lig" }), void 0), _jsx("span", __assign({ className: "hidden-sm hidden-detail" }, { children: "Lig Se\u00E7imi" }), void 0)] }), void 0), props.params.eventType != "uzunvadeli" ? (_jsxs(_Fragment, { children: [_jsxs("button", __assign({ className: "btn btn-small " + (sportsbookFilter.filterDays.length > 0 ? 'btn-warning' : 'btn-outline-white'), onClick: function () {
                                            setDaysModalIsOpened(true);
                                        } }, { children: [_jsxs("svg", __assign({ className: "visible-sm visible-detail", xmlns: "http://www.w3.org/2000/svg", width: "13", height: "13", viewBox: "0 0 24 24", fill: "none", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, { children: [_jsx("rect", { x: "3", y: "4", width: "18", height: "18", rx: "2", ry: "2" }, void 0), _jsx("line", { x1: "16", y1: "2", x2: "16", y2: "6" }, void 0), _jsx("line", { x1: "8", y1: "2", x2: "8", y2: "6" }, void 0), _jsx("line", { x1: "3", y1: "10", x2: "21", y2: "10" }, void 0)] }), void 0), _jsx("span", __assign({ className: "hidden-sm hidden-detail" }, { children: "Tarih Se\u00E7imi" }), void 0)] }), void 0), _jsxs("button", __assign({ className: "btn btn-small " + (sportsbookFilter.filterLiveEvent ? 'btn-warning' : 'btn-outline-white'), onClick: function () {
                                            sportsbookFilter.filterLiveEvent = !sportsbookFilter.filterLiveEvent;
                                            parentForceUpdate();
                                        } }, { children: [_jsx("span", __assign({ className: "visible-sm visible-detail" }, { children: "Canl\u0131" }), void 0), _jsx("span", __assign({ className: "hidden-sm hidden-detail" }, { children: "Canl\u0131 Ma\u00E7lar" }), void 0)] }), void 0), _jsxs("button", __assign({ className: "btn btn-small " + (sportsbookFilter.filterMBS.length > 0 ? 'btn-warning' : 'btn-outline-white'), onClick: function () {
                                            setFilterMBS(1);
                                            // forceUpdate();
                                            parentForceUpdate();
                                        } }, { children: [_jsx("span", __assign({ className: "visible-sm visible-detail" }, { children: "Tek" }), void 0), _jsx("span", __assign({ className: "hidden-sm hidden-detail" }, { children: "Tek Ma\u00E7" }), void 0)] }), void 0), _jsxs("button", __assign({ className: "btn btn-small " + (sportsbookFilter.filterCustomRatio ? 'btn-warning' : 'btn-outline-white'), onClick: function () {
                                            sportsbookFilter.filterCustomRatio = !sportsbookFilter.filterCustomRatio;
                                            parentForceUpdate();
                                        } }, { children: [_jsxs("span", __assign({ className: "visible-sm visible-detail" }, { children: [_jsx("img", { src: iconCustomRatio, className: "rapidIcon" }, void 0), "Y. O."] }), void 0), _jsxs("span", __assign({ className: "hidden-sm hidden-detail" }, { children: [_jsx("img", { src: iconCustomRatio, className: "rapidIcon" }, void 0), "Y\u00FCksek Oran"] }), void 0)] }), void 0), _jsxs("button", __assign({ className: "btn btn-small " + (sportsbookFilter.filterAlarmSet ? 'btn-warning' : 'btn-outline-white'), onClick: function () {
                                            sportsbookFilter.filterAlarmSet = !sportsbookFilter.filterAlarmSet;
                                            parentForceUpdate();
                                        } }, { children: [_jsxs("svg", __assign({ className: "visible-sm visible-detail", xmlns: "http://www.w3.org/2000/svg", width: "13", height: "13", viewBox: "0 0 24 24", fill: "none", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, { children: [_jsx("path", { d: "M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" }, void 0), _jsx("path", { d: "M13.73 21a2 2 0 0 1-3.46 0" }, void 0)] }), void 0), _jsx("span", __assign({ className: "hidden-sm hidden-detail" }, { children: "Takip Listem" }), void 0)] }), void 0)] }, void 0)) : null, _jsx("input", { id: "id_KeywordFilter", placeholder: "Tak\u0131m ad\u0131 ara", type: "text", className: "btn btn-small btn-outline-white text-left fw-normal", onChange: function (e) { return setKeywordFilter(e.target.value); } }, void 0), _jsx("div", __assign({ className: "clearFilter " + (sportsbookFilter.filterKeyword === '' ? ' hide ' : null), onClick: function () { return setKeywordFilter(''); } }, { children: "X" }), void 0)] }, void 0) }), void 0) }), void 0)] }, void 0));
}
export var SportsBookFilter = React.memo(SportsBookFilterComponent);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Odd } from "./odd";
import { myHighLightedMarketGroups } from "../../../services/sportsbook.services";
export function HighlightedMarkets(props) {
    // fixme: re-render edilmesede bu kısım sürekli çalıştırılıyor gibi kontrol et
    // console.log(props.event.eventId)
    var highlightedMarkets = props.highlightedMarkets;
    var event = props.event;
    // console.log(highlightedMarkets)
    // const event = props.event;
    var hlGroups = myHighLightedMarketGroups[event.sportSlug];
    return highlightedMarkets ? (_jsx(_Fragment, { children: hlGroups.map(function (h, key) {
            if (highlightedMarkets[h[Number(event.bettingPhase) === 0 ? 'pre' : 'live']] === undefined) {
                var col = 12 / h.outcomeCount;
                return (_jsxs("div", __assign({ className: "group " + (key === 2 ? 'last-group hidden-detail' : (key === 1 ? 'middle-group' : '')) }, { children: [_jsxs("div", __assign({ className: "col-".concat(col) }, { children: [_jsx("div", __assign({ className: "outcome-name text-center " }, { children: _jsx("span", __assign({ className: "label" }, { children: h.labels[0] }), void 0) }), void 0), _jsx("button", __assign({ className: "btn btn-odds locked" }, { children: _jsx("div", { children: " -" }, void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "col-".concat(col) }, { children: [_jsx("div", __assign({ className: "outcome-name text-center " }, { children: _jsx("span", __assign({ className: "label" }, { children: h.labels[1] }), void 0) }), void 0), _jsx("button", __assign({ className: "btn btn-odds locked" }, { children: _jsx("div", { children: " -" }, void 0) }), void 0)] }), void 0), Number(h.outcomeCount) === 3 ? _jsxs("div", __assign({ className: "col-".concat(col) }, { children: [_jsx("div", __assign({ className: "outcome-name text-center " }, { children: _jsx("span", __assign({ className: "label" }, { children: h.labels[2] }), void 0) }), void 0), _jsx("button", __assign({ className: "btn btn-odds locked" }, { children: _jsx("div", { children: " -" }, void 0) }), void 0)] }), void 0) : _jsx(_Fragment, {}, void 0)] }), key));
            }
            else {
                return (_jsx("div", __assign({ className: "group " + (key === 2 ? 'last-group hidden-detail' : (key === 1 ? 'middle-group' : '')) }, { children: _jsx(Odd, { event: event, market: highlightedMarkets[h[Number(event.bettingPhase) === 0 ? 'pre' : 'live']] }, void 0) }), key));
            }
        }) }, void 0)) : (_jsx(_Fragment, {}, void 0));
}
export function TrendMarkets(props) {
    // fixme: re-render edilmesede bu kısım sürekli çalıştırılıyor gibi kontrol et
    // console.log(props.event.eventId)
    var trendMarkets = props.trendMarkets;
    var event = props.event;
    var width = window.innerWidth;
    return (_jsx(_Fragment, {}, void 0));
}
